





















import useSelectItems from '@/use/selectItems'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        name: '',
        languageVersion: ''
      })
    }
  },

  setup(_, { root }) {

    const { languageItems } = useSelectItems({ root })

    return { languageItems }
  }
})
